import h from 'hyperscript';
import generateIcon from 'components/_particles/icon/icon';

class ArticleOverview {
  constructor($el) {
    this.$el = $el;
    this.$gridLayout = this.$el.classList.contains('article-overview--grid-layout');
    this.$regionSelectInputs = this.$el.querySelectorAll('.article-overview__select input[type=checkbox]');
    this.$categoryButtons = this.$el.querySelectorAll('.article-overview__navigation-item');
    this.$resultsContainer = this.$el.querySelector('.article-overview__results');
    this.$results = this.$el.querySelectorAll('.article-overview__result');
    this.$pagination = this.$el.querySelector('.article-overview__pagination');
    this.$adjustHeightDelay = undefined;
    this.$overflowCheckDelay = undefined;
    this.$windowResizeDelay = undefined;

    this.resultsPerPage = parseInt(this.$el.dataset.resultsPerPage, 10);

    this.applyFilter();
    this.initEvents();
  }

  initEvents() {
    this.$regionSelectInputs.forEach(($checkbox) => {
      $checkbox.addEventListener('change', () => {
        this.applyFilter();
      });
    });

    this.$categoryButtons.forEach(($button) => {
      $button.addEventListener('click', () => {
        this.$categoryButtons.forEach(_$button => _$button.classList.remove('article-overview__navigation-item--active'));
        $button.classList.add('article-overview__navigation-item--active');

        this.applyFilter();
      });
    });

    if(this.$gridLayout) {
      this.$results.forEach((resultElem) => {
        let lazyloadElem = resultElem.querySelector('.js-lazyload');
        if(lazyloadElem) {
          lazyloadElem.addEventListener('load',(e)=>{
            this.adjustResultsHeight();
          });
        }
      });

      window.addEventListener('resize', (e) => {
        window.clearInterval(this.$windowResizeDelay);
        this.$windowResizeDelay = window.setInterval(() => {
          window.clearInterval(this.$windowResizeDelay);
          if(window.innerWidth >= 768) {
            this.adjustResultsHeight(true);
          }
        },200);
      });

      let filtersParam = new URLSearchParams(window.location.search).get('cat');
      if(filtersParam) {
        window.scrollTo({
          behavior: 'smooth',
          left: 0,
          top: (this.$el.querySelector('.article-overview__navigation').getBoundingClientRect().top + (window.innerHeight))
        });
      }

    }
  }

  initPagination($results, options) {
    this.$pagination.innerHTML = '';

    const pageCount = Math.ceil($results.length / this.resultsPerPage);

    if (pageCount > 1) {
      const $paginationItems = [...Array(pageCount)]
        .map((_, i) => h(`li.article-overview__pagination-item${options.page === i + 1 ? '.article-overview__pagination-item--active' : ''}`,
          h('a.article-overview__pagination-link', { href: '#' }, i + 1)));

      if (options.page < pageCount) {
        $paginationItems.push(
          h('li.article-overview__pagination-item',
            h('a.article-overview__pagination-link.article-overview__pagination-link--next', { href: '#' },
              generateIcon({
                icon: 'arrow-internal',
                classes: ['article-overview__pagination-link-icon'],
              }))),
        );
      }

      this.$pagination.append(h('ul.article-overview__pagination-items', $paginationItems));
      this.$pagination.querySelectorAll('.article-overview__pagination-link').forEach(($link, pageIndex) => {
        $link.addEventListener('click', (event) => {
          event.preventDefault();

          if ($link.classList.contains('article-overview__pagination-link--next')) {
            this.applyFilter({ page: options.page + 1 });
          } else {
            this.applyFilter({ page: pageIndex + 1 });
          }
        });
      });
    }
  }

  applyFilter(paginationOptions = { page: 1 }, resize = true) {
    const $filteredResults = this.filterResults({
      regionIds: this.selectedRegionIds(),
      categoryId: this.selectedCategoryId(),
      paginationOptions,
    });

    this.initPagination($filteredResults, paginationOptions);

    this.adjustResultsHeight(resize);
  }

  selectedRegionIds() {
    return [...this.$regionSelectInputs]
      .filter($input => $input.checked)
      .map($input => $input.id);
  }

  selectedCategoryId() {
    return this.$el
      .querySelector('.article-overview__navigation-item--active')
      .dataset.id;
  }

  filterResults(options = {}) {
    let $filteredResults = this.$results;
    $filteredResults = options.regionIds && options.regionIds.length
      ? this.resultsByRegions($filteredResults, options.regionIds) : $filteredResults;
    $filteredResults = options.categoryId && options.categoryId !== 'all'
      ? this.resultsByCategory($filteredResults, options.categoryId) : $filteredResults;

    // Apply filters
    this.$results.forEach($result => $result.classList[
      [...$filteredResults].includes($result) ? 'remove' : 'add'
    ]('article-overview__result--hidden'));

    // Apply pagination
    this.$el.querySelectorAll('.article-overview__result:not(.article-overview__result--hidden)')
      .forEach(($result, i) => {
        const start = (options.paginationOptions.page - 1) * this.resultsPerPage;
        const end = start + this.resultsPerPage - 1;
        const range = Array(end - start + 1).fill().map((_, index) => start + index);

        if (!range.includes(i)) {
          $result.classList.add('article-overview__result--hidden');
        }
      });

    return $filteredResults;
  }

  resultsByRegions($results, regionIds) {
    return [...$results]
      .filter($result => regionIds.some(regionId => $result.dataset.regionIds.split(',').includes(regionId)));
  }

  resultsByCategory($results, categoryId) {
    return [...$results]
      .filter($result => $result.dataset.categoryIds.split(',').includes(categoryId));
  }


  resultsOverflow() {
    return this.$resultsContainer.offsetHeight < this.$resultsContainer.scrollHeight || this.$resultsContainer.offsetWidth < this.$resultsContainer.scrollWidth;
  }

  adjustResultsHeight(reset) {
    if(this.$gridLayout) {
      if(window.innerWidth >= 768) {
        if(reset) {
          this.$resultsContainer.style.height = 0;
        }
        window.clearInterval(this.$adjustHeightDelay);
        this.$adjustHeightDelay = window.setInterval(() => {
          if (this.resultsOverflow()) {
            this.$resultsContainer.style.height = parseInt(getComputedStyle(this.$resultsContainer).height,10) + 20 + 'px';
          } else {
            window.clearInterval(this.$adjustHeightDelay);
          }
        }, 5);

        window.clearInterval(this.$overflowCheckDelay);
        this.$overflowCheckDelay = window.setInterval(() => {
          window.clearInterval(this.$overflowCheckDelay);
          if(this.resultsOverflow()) {
            this.adjustResultsHeight();
          }
        },200);
      }
    }   
    
  }
}

document.querySelectorAll('.js-article-overview')
  .forEach($el => new ArticleOverview($el));
