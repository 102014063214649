class Map {
    constructor(el) {
        this.$el = el;
        this.$id = this.$el.querySelector('.map__map').id;
        this.$activateMapButton = this.$el.querySelector('.button--activate-map');

        this.InitEvents();
    }

    InitEvents() {
        // Activate Map Button
        this.$activateMapButton.addEventListener('click', (e) => {
            e.preventDefault();

            this.$el.classList.add('map--active');
            
            setTimeout(() => {
                window[this.$id].invalidateSize();
            }, 300);
        });
    }
}

document.querySelectorAll('.js-map').forEach(el => new Map(el));