class ImageOverlay {
    constructor(el) {
        this.$el = el;
        this.$template = this.$el.parentNode.querySelector('.image-overlay template');
        this.$activeOverlay = undefined;

        this.InitEvents();
    }

    InitEvents() {
        this.$el.addEventListener('click',(e)=>{
            e.preventDefault();
            document.querySelector('.page__content').appendChild(document.importNode(this.$template.content, true));
            this.$activeOverlay = document.querySelector('.page__content .image-overlay__bgrd');
            
            this.BindOverlayCloser();
        });
    }

    BindOverlayCloser(closerElem) {
        this.$activeOverlay.querySelector('.image-overlay__window-closer').addEventListener('click',(e)=>{
            e.preventDefault();
            this.RemoveOverlay();
        });
    }

    RemoveOverlay() {
        this.$activeOverlay.remove();
        this.$activeOverlay = undefined;
    }

}

document.querySelectorAll('.js-image-overlay').forEach((el) => new ImageOverlay(el));