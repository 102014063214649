class VideoModule {
    constructor(el) {
        this.$el = el;
        this.$playButton = this.$el.querySelector('.video-module__play-button');
        this.$video = this.$el.querySelector('video');

        this.InitEvents();
    }

    InitEvents() {
        var self = this;
        this.$playButton.addEventListener('click', (e) => {
            e.preventDefault();
            this.$el.classList.add('video-module--playing');
            this.$video.play();
        });
        this.$video.addEventListener('pause', (e) => {
            if(!self.$video.seeking) {
                self.$el.classList.remove('video-module--playing');
            }
        });
    }

    PauseVideo() {
        this.$el.classList.remove('video-module--playing');
    }
}

document.querySelectorAll('.js-video-module').forEach(el => window[el.id] = new VideoModule(el));