class YtVideo {
    constructor(el) {
        this.$el = el;
        this.$playButton = this.$el.querySelector('.yt-video__play-button');
        this.$template = this.$el.querySelector('template');
        this.$ytIframe = undefined;

        this.InitEvents();
    }

    InitEvents() {
        this.$playButton.addEventListener('click', (e) => {
            e.preventDefault();
            this.$el.classList.add('yt-video--loaded');
            this.$el.appendChild(document.importNode(this.$template.content, true));
            this.$ytIframe = this.$el.querySelector('iframe');
        });
    }

    PauseVideo() {
        if(typeof this.$ytIframe !== "undefined") {
            this.$ytIframe.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
        }
    }
}

document.querySelectorAll('.js-yt-video').forEach(el => window[el.id] = new YtVideo(el));