class SliderMedia {
    constructor(el) {
        this.$el = el;
        this.$prevButton = this.$el.querySelector('.icon-link--slider-media-left');
        this.$nextButton = this.$el.querySelector('.icon-link--slider-media-right');
        this.$track = this.$el.querySelector('.slider-media__slides');
        this.$slides = this.$el.querySelectorAll('.slider-media__slide');
        this.$previews = this.$el.querySelectorAll('.slider-media__preview');
        this.$counterMobile = this.$el.querySelector('.slide-media__controls__count span:first-child');
        this.$active = 0;
        this.$count = this.$slides.length;
        this.$videos = this.$el.querySelectorAll('video');
        this.$ytVideos = this.$el.querySelectorAll('.yt-video');

        this.$el.querySelectorAll('.slider-media__slide-content__count span:first-child').forEach((counter, index) => {
            counter.innerText = this.DisplayCount(index + 1);
        });
        this.$counterMobile.innerText = this.DisplayCount(this.$active + 1);

        this.InitEvents();
    }

    ButtonControl(direction) {
        if(direction === 'left') {
            this.$active++;
        } else {
            this.$active--;
        }
        this.Slide();
    }

    DisplayCount(active) {
        return "0".repeat((this.$count - (this.$count - (active))).toString().length - 1) + (active);
    }

    InitEvents() {
        this.$prevButton.addEventListener('click',(e) => {
            e.preventDefault();
            this.ButtonControl('right');
            this.$prevButton.blur();
        });

        this.$nextButton.addEventListener('click',(e) => {
            e.preventDefault();
            this.ButtonControl('left');
            this.$nextButton.blur();
        });

        this.$previews.forEach((preview, index) => {
            preview.addEventListener('click',(e) => {
                e.preventDefault();
                this.$active = index;
                this.Slide();
            });
        });
    }

    Slide() {
        this.$active = this.$active < 0 ? this.$count - 1 : (this.$active > this.$count - 1 ? 0 : this.$active);
        // MOBILE
        this.$track.style = 'width: calc(' + this.$count * 75 + 'vw + 2rem);transform: translateX(-' + (this.$active * 75) + 'vw);';
        // DESKTOP
        this.$el.querySelector('.slider-media__slide.active').classList.remove('active');
        this.$slides[this.$active].classList.add('active');
        this.$el.querySelector('.slider-media__preview.active').classList.remove('active');
        this.$previews[this.$active].classList.add('active');

        // COUNTER MOBILE
        this.$counterMobile.innerText = this.DisplayCount(this.$active + 1);

        // PAUSE RUNNING VIDEOS
        this.$videos.forEach((video) => {
            video.pause();
        });

        // PAUSE RUNNING YT VIDEOS
        this.$ytVideos.forEach((el) => {
            window[el.id].PauseVideo();
        });
    }
}

document.querySelectorAll('.js-slider-media').forEach((el) => new SliderMedia(el));