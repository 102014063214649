class ToTop {
  constructor($el) {
    this.$el = $el;
    this.$link = this.$el.querySelector('.to-top__icon-link');

    this.initEvents();
  }

  initEvents() {
    this.$link.addEventListener('click', (event) => {
      event.preventDefault();

      document.getElementById('top').scrollIntoView({ behaviour: 'smooth' });
    });
  }
}

document.querySelectorAll('.js-to-top')
  .forEach($el => new ToTop($el));
