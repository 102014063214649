class ReadMore {
  constructor($el) {
    this.$el = $el;
    this.$expandLink = this.$el.querySelector('.read-more__link--more');
    this.$collapseLink = this.$el.querySelector('.read-more__link--less');

    this.initEvents();
  }

  initEvents() {
    this.$expandLink.addEventListener('click', (event) => {
      event.preventDefault();

      this.$el.classList.add('read-more--expanded');
      this.$collapseLink.focus();
    });

    this.$collapseLink.addEventListener('click', (event) => {
      event.preventDefault();

      this.$el.classList.remove('read-more--expanded');
      this.$expandLink.focus();
    });
  }
}

document.querySelectorAll('.js-read-more')
  .forEach($el => new ReadMore($el));
