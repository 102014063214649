import Slider from 'javascripts/globals/slider';
import { BREAKPOINTS } from 'javascripts/constants';

const mql = [
  window.matchMedia(`(min-width: ${BREAKPOINTS.m})`),
  window.matchMedia(`(min-width: ${BREAKPOINTS.l})`),
];

let componentStore = [];

function onMediaQueryChange() {
  componentStore.forEach(component => component.deconstructor && component.deconstructor());
  componentStore = [];
  const isM = mql[0].matches;
  const isL = mql[1].matches;

  let slidesToScroll = 1;

  if (isL) {
    slidesToScroll = 1;
  } else if (isM) {
    slidesToScroll = 1;
  }

  document
    .querySelectorAll('.slider-image')
    .forEach(($slider) => {
      const $items = $slider.querySelectorAll('.slider-image__item');

      if ($items && ($items.length <= 2) && isL) {
        return;
      }

      componentStore.push(new Slider($slider, {
        sliderFrame: 'slider-image__frame',
        slidesContainer: 'slider-image__items',
        sliderSlide: 'slider-image__item',
        sliderControlsCount: 'slider-image__count',
        sliderControlsFrameLeft: 'slider-image__controls--left',
        sliderControlsFrameRight: 'slider-image__controls--right',
        sliderControlsFrameMethod: 'prepend',
        slidesToScroll,
      }));
    });
}

mql.forEach((el) => {
  el.addListener(onMediaQueryChange);
});

onMediaQueryChange();
