class AdvancedSelect {
  constructor($el) {
    this.$el = $el;
    this.$button = this.$el.querySelector('.advanced-select__button');
    this.$checkboxes = this.$el.querySelectorAll('input[type=checkbox]');

    this.initEvents();
  }

  initEvents() {
    this.$button.addEventListener('click', () => {
      this.$el.classList.toggle('advanced-select--open');
    });

    this.$checkboxes.forEach(($checkbox) => {
      $checkbox.addEventListener('change', () => {
        if (this.isSelected()) {
          this.$el.classList.add('advanced-select--selected');
        } else {
          this.$el.classList.remove('advanced-select--selected');
        }
      });
    });

    document.addEventListener('click', (event) => {
      if (!this.$el.contains(event.target)) {
        this.$el.classList.remove('advanced-select--open');
      }
    });
  }

  isSelected() {
    return [...this.$checkboxes].some($checkbox => $checkbox.checked);
  }
}

document.querySelectorAll('.js-advanced-select')
  .forEach($el => new AdvancedSelect($el));
