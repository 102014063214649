import { lory } from '@rsm/allfarblori';
import h from 'hyperscript';
import generateIcon from 'components/_particles/icon/icon';

export default class Slider {
  constructor($el, options) {
    this.$el = $el;

    // Classes have to be assigned to slider elements
    if (!options) {
      return;
    }

    this.options = {
      sliderControlsFrameMethod: 'append',
      rewind: true,
      slidesToScroll: 1,
      slidesFocusable: false,
      scrollToActiveSlide: true,
      ...options,
    };

    // Slides
    this.$slides = this.$el.querySelectorAll(`.${this.options.sliderSlide}`);

    // Don't init slider, if only one slide exists
    if (this.$slides.length === 1) {
      return;
    }

    this.slidesTotal = this.$slides.length;

    if (this.options.slidesToScroll > 1) {
      this.slidesTotal = Math.ceil(this.$slides.length / this.options.slidesToScroll);
    }

    this.initSlider();
  }

  initClasses() {
    // Frame
    this.$sliderFrame = this.$el.querySelector(`.${this.options.sliderFrame}`);

    // Container: Collection of slides
    this.$slidesContainer = this.$el.querySelector(`.${this.options.slidesContainer}`);

    // Controls-Frame: Where to dock the controls
    this.$controlsCount = this.$el.querySelector(`.${this.options.sliderControlsCount}`);
    this.$controlsFrameLeft = this.$el.querySelector(`.${this.options.sliderControlsFrameLeft}`);
    this.$controlsFrameRight = this.$el.querySelector(`.${this.options.sliderControlsFrameRight}`);
    this.controlsFrameMethod = this.options.sliderControlsFrameMethod;

    this.$sliderFrame.classList.add('slider__frame');
    this.$slidesContainer.classList.add('slider__container');

    if (this.$slides) {
      this.$slides.forEach(($item) => {
        if (this.options.slidesFocusable) {
          $item.setAttribute('tabindex', '0');
        }

        $item.classList.add('slider__slide');
      });
    }
  }

  initSlider() {
    this.initClasses();

    this.$el.addEventListener('before.lory.init', () => {
      this.$slidesContainer.scrollLeft = 0;
      this.initControls();
    });

    this.$el.addEventListener('after.lory.init', () => {
      this.$el.classList.add('slider--initialized');
    });

    this.$el.addEventListener('after.lory.slide', () => {
      this.updateControls();
    });

    this.lory = lory(this.$el, {
      slidesToScroll: this.options.slidesToScroll,
      rewind: this.options.rewind,
      rewindPrev: true,
      rewindSpeed: 200,
      slideSpeed: 350,
      ease: 'cubic-bezier(0.455, 0.03, 0.515, 0.955)',
      classNameFrame: this.options.sliderFrame,
      classNameSlideContainer: this.options.slidesContainer,
      classNamePrevCtrl: 'slider__control--prev',
      classNameNextCtrl: 'slider__control--next',
    });

    if (this.$slidesContainer) {
      this.$slidesContainer.addEventListener('focusin', this.focusIn);
    }

    window.addEventListener('resize', this.onResize);

    this.lory.reset();
  }

  initControls() {
    if (this.$controls) {
      return;
    }

    const $controlPrev = h(
      'button.slider__control.slider__control--prev',
      generateIcon({
        icon: 'chevron-left',
      }),
    );

    $controlPrev.setAttribute('aria-hidden', true);
    $controlPrev.setAttribute('tabindex', -1);

    const $controlNext = h(
      'button.slider__control.slider__control--next',
      generateIcon({
        icon: 'chevron-right',
      }),
    );

    $controlNext.setAttribute('aria-hidden', true);
    $controlNext.setAttribute('tabindex', -1);

    this.$controls = {
      $prev: h('.slider__controls', $controlPrev),
      $next: h('.slider__controls', $controlNext),
    };

    // Method defaults to append
    if (this.controlsFrameMethod) {
      this.$controlsFrameLeft[this.controlsFrameMethod](this.$controls.$prev);
      this.$controlsFrameRight[this.controlsFrameMethod](this.$controls.$next);
    }

    // Counter
    this.$controlsCount.innerText = `1 / ${this.$slides.length}`;
  }

  updateControls() {
    const currentIndex = this.lory.returnIndex() + 1;
    const shownIndex = Math.ceil(currentIndex / this.options.slidesToScroll);

    this.$el.querySelectorAll('.slider__page').forEach(($el, i) => {
      $el.classList[shownIndex - 1 === i ? 'add' : 'remove']('slider__page--current');
    });

    this.$controlsCount.innerText = `${currentIndex} / ${this.$slides.length}`;
  }

  onResize = () => {
    this.updateControls();
  }

  deconstructor() {
    this.$el.querySelectorAll('.slider__controls').forEach(($el) => {
      $el.parentNode.removeChild($el);
    });

    this.$el.classList.remove('slider--initialized');
    this.$sliderFrame.classList.remove('slider__frame');
    this.$slidesContainer.classList.remove('slider__container');

    if (this.$slides) {
      this.$slides.forEach(($item) => {
        if (this.options.slidesFocusable) {
          $item.removeAttribute('tabindex', null);
        }
        $item.classList.remove('slider__slide');
      });
    }

    if (this.lory) {
      this.lory.destroy();
    }
  }

  focusIn = (event) => {
    if (event.target) {
      const className = this.$slides[0].classList[0];
      this.$sliderFrame.scrollLeft = 0;
      const $slide = event.target.closest(`.${className}`);
      const index = Array.prototype.indexOf.call(this.$slides, $slide);
      this.lory.slideTo(index);

      if (this.options.scrollToActiveSlide) {
        const scrollOffset = -150;
        const scroll = $slide.getBoundingClientRect().top + window.pageYOffset + scrollOffset;
        window.scrollTo({ top: scroll, behavior: 'smooth' });
      }
    }
  }
}
