import h from 'hyperscript';

class OverallArticleOverview {
  constructor($el) {
    this.$el = $el;
    this.$gridLayout = this.$el.classList.contains('overall-article-overview--grid-layout');
    this.$categoryButtons = this.$el.querySelectorAll('.overall-article-overview__navigation-item');
    this.$resultsContainer = this.$el.querySelector('.overall-article-overview__results');
    this.$results = this.$el.querySelectorAll('.overall-article-overview__result');
    this.$pagination = this.$el.querySelector('.overall-article-overview__pagination');
    this.$showAllButton = this.$el.querySelector('.overall-article-overview__show-all');
    this.$adjustHeightDelay = undefined;
    this.$overflowCheckDelay = undefined;
    this.$windowResizeDelay = undefined;

    this.resultsPerPage = parseInt(this.$el.dataset.resultsPerPage, 10);

    this.applyFilter();
    this.initEvents();
  }

  initEvents() {
    this.$categoryButtons.forEach(($button) => {
      $button.addEventListener('click', () => {
        this.$categoryButtons.forEach(_$button => _$button.classList.remove('overall-article-overview__navigation-item--active'));
        $button.classList.add('overall-article-overview__navigation-item--active');

        this.applyFilter();
      });
    });

    this.$showAllButton.addEventListener('click', () => {
      this.applyFilter({
        showAll: true,
      });
    });

    if(this.$gridLayout) {
      this.$results.forEach((resultElem) => {
        let lazyloadElem = resultElem.querySelector('.js-lazyload');
        if(lazyloadElem) {
          lazyloadElem.addEventListener('load',(e)=>{
            this.adjustResultsHeight();
          });
        }
      });

      window.addEventListener('resize', (e) => {
        window.clearInterval(this.$windowResizeDelay);
        this.$windowResizeDelay = window.setInterval(() => {
          window.clearInterval(this.$windowResizeDelay);
          if(window.innerWidth >= 768) {
            this.adjustResultsHeight(true);
          }
        },200);
      });
    }

  }

  initPagination($results, options) {
    this.$pagination.innerHTML = '';

    const pageCount = Math.ceil($results.length / this.resultsPerPage);

    if (pageCount > 1 && options.page < pageCount) {
      this.$showAllButton.removeAttribute('disabled');

      const $loadMoreButton = h(
        'button.button.overall-article-overview__load-more',
        h('span.button__text', 'Mehr Brandenburg erleben'),
      );

      $loadMoreButton.addEventListener('click', () => {
        this.applyFilter({ page: options.page + 1 }, false);
      });

      this.$pagination.append($loadMoreButton);
    } else {
      this.$showAllButton.setAttribute('disabled', 'true');
    }
  }

  applyFilter(paginationOptions = { page: 1 }, resize = true) {
    const $filteredResults = this.filterResults({
      categoryId: this.selectedCategoryId(),
      paginationOptions,
    });

    this.initPagination($filteredResults, paginationOptions);
    
    this.adjustResultsHeight(resize);
  }

  selectedCategoryId() {
    return this.$el
      .querySelector('.overall-article-overview__navigation-item--active')
      .dataset.id;
  }

  filterResults(options = {}) {
    let $filteredResults = this.$results;
    $filteredResults = options.categoryId && options.categoryId !== 'all'
      ? this.resultsByCategory($filteredResults, options.categoryId) : $filteredResults;

    // Apply filters
    this.$results.forEach(($result) => {
      $result.classList[[...$filteredResults].includes($result) ? 'remove' : 'add']('overall-article-overview__result--hidden');
    });

    // Apply pagination
    if (!options.paginationOptions.showAll) {
      this.$el.querySelectorAll('.overall-article-overview__result:not(.overall-article-overview__result--hidden)')
        .forEach(($result, i) => {
          if (i > (options.paginationOptions.page * this.resultsPerPage) - 1) {
            $result.classList.add('overall-article-overview__result--hidden');
          }
        });
    }

    return $filteredResults;
  }

  resultsByCategory($results, categoryId) {
    return [...$results]
      .filter($result => $result.dataset.categoryIds.split(',').includes(categoryId));
  }

  resultsOverflow() {
    return this.$resultsContainer.offsetHeight < this.$resultsContainer.scrollHeight || this.$resultsContainer.offsetWidth < this.$resultsContainer.scrollWidth;
  }

  adjustResultsHeight(reset) {
    if(this.$gridLayout) {
      if(window.innerWidth >= 768) {
        if(reset) {
          this.$resultsContainer.style.height = 0;
        }
        window.clearInterval(this.$adjustHeightDelay);
        this.$adjustHeightDelay = window.setInterval(() => {
          if (this.resultsOverflow()) {
            this.$resultsContainer.style.height = parseInt(getComputedStyle(this.$resultsContainer).height,10) + 20 + 'px';
          } else {
            window.clearInterval(this.$adjustHeightDelay);
          }
        }, 5);

        window.clearInterval(this.$overflowCheckDelay);
        this.$overflowCheckDelay = window.setInterval(() => {
          window.clearInterval(this.$overflowCheckDelay);
          if(this.resultsOverflow()) {
            this.adjustResultsHeight();
          }
        },200);
      }
    }
    
  }

}

document.querySelectorAll('.js-overall-article-overview')
  .forEach($el => new OverallArticleOverview($el));
