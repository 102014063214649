import * as focusTrap from 'focus-trap';

import applyOverlayLinks from 'javascripts/globals/overlay-link';
import { attachTooltip } from 'components/atoms/tooltip/tooltip';

class Header {
  constructor($el) {
    this.$el = $el;
    this.$burgerButton = this.$el.querySelector('.header__burger-button');
    this.$searchButton = this.$el.querySelector('.header__search-button');
    this.$searchCloseButton = this.$el.querySelector('.header__search-close-button');
    this.$searchInput = this.$el.querySelector('.header__search-input');
    this.$searchFilters = this.$el.querySelectorAll('.header__filter-option-input');
    this.$flyout = this.$el.querySelector('.header__flyout');
    this.$search = this.$el.querySelector('.header__search');
    this.$results = this.$el.querySelector('.header__results');
    this.$loadingBar = this.$el.querySelector('.header__loader-bar-inner');

    this.dataSource = this.$search.dataset.source;
    this.trap = focusTrap.createFocusTrap(this.$flyout);

    this.initEvents();
  }

  initEvents() {
    this.$burgerButton.addEventListener('click', (event) => {
      event.preventDefault();

      this.$el.classList.toggle('menu-open');
    });

    this.$searchButton.addEventListener('click', (event) => {
      event.preventDefault();

      if (this.$flyout.classList.contains('header__flyout--open')) {
        this.closeFlyout();
      } else {
        this.openFlyout();
      }
    });

    this.$searchCloseButton.addEventListener('click', (event) => {
      event.preventDefault();

      this.closeFlyout();
    });

    this.$searchInput.addEventListener('keydown', (event) => {
      if (event.keyCode === 27) {
        this.closeFlyout();
      }
    });

    this.$searchInput.addEventListener('input', () => this.fetchResults());

    this.$searchFilters.forEach(($input) => {
      $input.addEventListener('click', () => {
        this.fetchResults();
      });
    });
  }

  openFlyout() {
    this.$flyout.classList.add('header__flyout--open');
    document.getElementById('page').classList.add('page--fixed');

    this.trap.activate();
    this.$searchInput.focus();
  }

  closeFlyout() {
    this.$flyout.classList.remove('header__flyout--open');
    document.getElementById('page').classList.remove('page--fixed');

    this.trap.deactivate();
    this.$searchButton.focus();
  }

  setFlyoutLoading() {
    this.$flyout.classList.add('header__flyout--loading');

    setTimeout(() => {
      this.$loadingBar.classList.add('header__loader-bar-inner--full');
    }, 100);
  }

  removeFlyoutLoading() {
    this.$flyout.classList.remove('header__flyout--loading');
    this.$loadingBar.classList.remove('header__loader-bar-inner--full');
  }

  fetchResults() {
    if (this.$searchInput.value === '') {
      this.$results.innerHTML = '';
    } else {
      let fetchUrl = this.dataSource.includes('.html') ? this.dataSource : `${this.dataSource}/?q=${this.$searchInput.value}`;

      const filterParameters = [...this.$searchFilters]
        .filter($input => $input.checked)
        .map($input => `${$input.value}`)
        .join(',');

      if (filterParameters.length) {
        fetchUrl = `${fetchUrl}&filters=${filterParameters}`;
      }

      this.get(fetchUrl, this.updateResults.bind(this));
    }
  }

  updateResults(data) {
    if (data.length) {
      this.$results.innerHTML = data;

      document.querySelectorAll('.js-tooltip').forEach(attachTooltip);

      applyOverlayLinks();
    } else {
      this.$results.innerHTML = '';
    }
  }

  get(url, successCallback) {
    this.setFlyoutLoading();
    this.req = new XMLHttpRequest();
    this.req.open('get', url);
    this.req.onload = () => {
      setTimeout(() => this.removeFlyoutLoading(), 1000);

      if (this.req.status === 200) successCallback(this.req.response);
      else Error(this.req.statusText);
    };

    this.req.send();
  }
}

document.querySelectorAll('.js-header').forEach($el => new Header($el));
