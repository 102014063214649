class Teaser{
    constructor(el) {
        this.$el = el;
        this.$nextFullContainer = this.GetNextFullContainer() || document.querySelector('.detail-content__block:last-child');
        this.$scrollMargin = {top: 30, bottom: 60};
        this.$hidden = false;

        this.InitEvents();
    }

    InitEvents() {
        document.addEventListener('scroll',(e) => {
            if(window.innerWidth >= 1320 && !this.$hidden) {
                let transformPos = window.scrollY - (this.$el.closest('.detail-content__block--teaser').offsetTop - this.$scrollMargin.top);
                let maxTransformPos =  (this.$el.closest('.detail-content__block--teaser').offsetTop - (this.$nextFullContainer.offsetTop - this.$el.offsetHeight - this.$scrollMargin.bottom)) * (-1);
                if(maxTransformPos < 0) {
                    this.$hidden = true;
                    this.$el.classList.add('teaser--hidden');
                }
                transformPos = transformPos >= 0 ? transformPos : 0;
                transformPos = maxTransformPos - transformPos >= 0 ? transformPos : maxTransformPos;
                this.$el.style.transform = 'translateY(' + (transformPos) + 'px)';
            }
        });
    }

    GetNextFullContainer() {
        let el = this.$el.closest('.detail-content__block--teaser').nextElementSibling;
        while(el) {
            if(el.classList.contains('detail-content__block--full')) {
                return el;
                break;
            } else {
                el = el.nextElementSibling;
            }
        }
        return false;
    }

}

document.querySelectorAll('.js-teaser').forEach(el => new Teaser(el));