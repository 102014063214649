class Accordion {
    constructor(el) {
        this.$el = el;
        this.$rowHeaders = this.$el.querySelectorAll('.accordion__row-header');

        this.InitEvents();
    }

    InitEvents(){
        this.$rowHeaders.forEach((rowHeader) => {
            rowHeader.addEventListener('click',(e) => {
                e.preventDefault();
                e.target.closest('.accordion__row').classList.toggle('accordion--row-open');
            });
        });
    }
}

document.querySelectorAll('.js-accordion').forEach((el) => new Accordion(el));