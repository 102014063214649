class VideoOverlay {
    constructor(el) {
        this.$el = el;
        this.$id = this.$el.dataset.id;
        this.$closeButton = this.$el.querySelector('.video-overlay__closer');
        this.$video = this.$el.querySelector('video');
        this.$repeats = this.$el.dataset.repeat || false;
        this.$storageItemName = 'video-overlay_' + this.$id;
        this.$storageItem = localStorage.getItem(this.$storageItemName) || false;

        this.InitEvents();
        this.ManageRepeatRate();
    }

    InitEvents() {
        this.$closeButton.addEventListener('click',(e) => {
            e.preventDefault();
            
            this.RemoveOverlay();
        });
    }

    ManageRepeatRate(){
        if(this.$repeats && this.$repeats > 0) {
            if(this.$storageItem) {
                let diffTime = new Date() - Date.parse(this.$storageItem);
                if ( Math.floor(diffTime / (1000 * 60 * 60 * 24)) < this.$repeats ) {
                    this.RemoveOverlay();
                } else {
                    localStorage.setItem(this.$storageItemName, new Date().toString());
                }
            } else {
                localStorage.setItem(this.$storageItemName, new Date().toString());
            }
        } else {
            localStorage.removeItem(this.$storageItemName);
        }
    }

    RemoveOverlay() {
        this.$el.remove();
    }
}

document.querySelectorAll('.js-video-overlay').forEach((el) => new VideoOverlay(el));

module.exports = VideoOverlay;