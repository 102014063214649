VideoOverlay = require('../../molecules/video-overlay/video-overlay');

class FeaturedHero {
    constructor(el) {
        this.$el = el;
        this.$videoTiles = this.$el.querySelectorAll('.featured-hero__tile--videoTile');

        this.InitEvents();
    }

    InitEvents() {
        this.$videoTiles.forEach((videoTile) => {
            videoTile.addEventListener('click', (e) => {
                e.preventDefault();
                document.querySelector('.page__content').appendChild(document.importNode(videoTile.querySelector('template').content, true));
                let videoOverlay = new VideoOverlay(document.querySelector('.js-video-overlay'));
            });
        });
    }
}

document.querySelectorAll('.js-featured-hero').forEach((el) => { new FeaturedHero(el) });